<template>
  <router-view></router-view>
</template>

<script>
import store from '@/store';

export default {
  name: 'Camera',
  beforeRouteEnter(to, from, next) {
    getPageData(to, next);
  },
  beforeRouteUpdate(to, from, next) {
    getPageData(to, next);
  },
};

async function getPageData(routeTo, next) {
  try {
    const isPublic = routeTo.params.esPublico == 'public';
    const dispatchCamara = isPublic ? 'camera/fetchCameraLibre' : 'camera/fetchCamera';
    
    const camera = await store.dispatch(dispatchCamara, routeTo.params.id);
    console.log("🚀 getPageData ~ camera:", camera)
    if(!isPublic){ //es privada
      const project = await store.dispatch('project/fetchProject', camera.project_id);
      document.title = `${project.name} - Timelapse México`;
      next();
    }else{
      if(camera.allow_public){
        next(); 
      }else{
        next({ name: '404', params: { resource: 'cámara' } });
      }
    }
    //next();
  } catch (error) {
    console.log("🚀 ~ file: Camera.vue:25 ~ getPageData ~ error:", error)
    if (error.response && error.response.status == 404) {
      next({ name: '404', params: { resource: 'cámara' } });
    } else {
      next({ name: 'network-issue' });
    }
  }
}
</script>
